<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-transition"
       >

        <v-card tile >
          <v-toolbar max-height="80px"      
            dark
            color="#120222"
          >
            <v-btn
              icon
              dark
              @click="$emit('close'), $emit('atualizar')"
            > <!-- $emit('close')-->
              <v-icon>mdi-close</v-icon> 
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form 
            ref="form"
            lazy-validation                  
            class="justify-center mx-auto text-center">
                <v-row class="justify-center mx-auto text-center">
                  <v-col class="justify-center mx-auto text-center">
                      <div>
                        <div class="imagem3-upload text-center mx-auto">
                         <div>
                          <input 
                          type="file" 
                          @change="pickFile1"
                          name="file1"
                          ref="fileInput" required
                          class="input-file">
                          <button>
                            <v-icon
                            class="mt-15"
                            dense color="red" @click="selectImage"
                            v-if="loading == false & previewImage == null & error == true"
                            >mdi-cloud-alert</v-icon>
                            <v-icon
                            class="mt-15"
                            @click="selectImage" 
                            v-if="previewImage == null & loading == false & error == false">mdi-cloud-upload</v-icon>
                            <v-progress-circular
                            indeterminate
                            color="purple"  class="mt-15"
                            v-if=" loading == true & previewImage == null & error == false"
                            @click="selectImage"
                            ></v-progress-circular>
                          </button>
                        </div>
                        <div
                        class=" justify-center imagePreviewWrapper"
                        :style="{ 'background-image': `url(${previewImage})` }">
                        </div>
                        </div>
                      </div>
                  </v-col>
                </v-row> 
              <div class="text-center mt-5">   
              <v-text-field     
                type="text"  v-model="restaurante.name"
                label="Nome" 
                required>
                </v-text-field>
                 <v-select     
                type="text"  v-model="restaurante.type"
                label="Tipo" 
                :items="types"
                required>
                </v-select>
                 <v-select     
                type="text"  v-model="restaurante.openTime.days"
                label="Dias Abertos" 
                item-text="name" 
                item-value="_id" 
                :items="dias" multiple
                required>
                </v-select>
                <v-text-field     
                type="text"  v-model="restaurante.googleLink"
                label="Link Google" 
                required>
                </v-text-field>
                <v-textarea
                height="120px"
                class=" uso-mascara" 
                type="text" 
                label="Descrição" 
                v-model="restaurante.description" 
                required>
              </v-textarea>
                  <v-row>
                    <v-col  cols="6" class="justify-center mx-auto text-center">
                        <div class="justify-center mx-auto text-center">
                          <v-row class="box-input  justify-center mx-auto text-center">
                            
                                  
                            <v-dialog
                              ref="dialog1"
                              v-model="modal1"
                              :return-value.sync="time"
                              persistent
                              width="300px"
                              >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="time1" :change="MakeTime()"
                                label="Horário Abertura"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                              v-if="modal1"
                              format="24hr"
                              v-model="time1"
                              full-width
                              color="purple"
                              >
                              <v-spacer></v-spacer>
                              <v-btn
                              text
                              color="purple"
                              @click="modal1 = false"
                              >
                              Cancel
                              </v-btn>
                              <v-btn
                              text
                              color="purple"
                              @click="$refs.dialog1.save(time)"
                              >
                              OK
                              </v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-row>        
                        </div>            
                    </v-col>
                    <v-col  cols="6" class="justify-center mx-auto text-center">
                        <div class="justify-center mx-auto text-center">
                          <v-row class="box-input  justify-center mx-auto text-center">
                            
                                  
                            <v-dialog
                              ref="dialog2"
                              v-model="modal2"
                              :return-value.sync="time"
                              persistent
                              width="300px"
                              >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="time2" :change="MakeTime2()"
                                label="Horário Fechamento"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                              v-if="modal2"
                              format="24hr"
                              v-model="time2"
                              full-width
                              color="purple"
                              >
                              <v-spacer></v-spacer>
                              <v-btn
                              text
                              color="purple"
                              @click="modal2 = false"
                              >
                              Cancel
                              </v-btn>
                              <v-btn
                              text
                              color="purple"
                              @click="$refs.dialog2.save(time)"
                              >
                              OK
                              </v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-row>        
                        </div>            
                    </v-col>

                  </v-row>
                <v-row class="uso-mascara mb-5 justify-center mx-auto text-center">
                  <v-col  cols="12" class="col-md-6 col-xs-6 mt-6">
                    <h5 >Estado:</h5>
                    <v-select
                    @change="getCidades()"
                    outlined 
                    :items="estados"
                    item-text="nome"
                    item-value="sigla"
                    v-model="restaurante.city.state"
                    dense
                    required></v-select>

                  </v-col>

                  <v-col  cols="12" class="col-md-6 col-xs-6 mt-6">
                    <h5>Cidade:</h5>
                    <v-select
                    outlined
                    :items="municipios"
                    item-text="nome"
                    iteme-value="nome"
                    v-model="restaurante.city.name"
                    dense
                    required>
                    </v-select>
                  </v-col>
                </v-row>
                  <v-row class="uso-mascara mb-5 justify-center mx-auto text-center">
                  <v-col  cols="12" class="col-md-6 col-xs-6 mt-6">
                    <v-select
                      v-model="restaurante.category"
                      :items="categorias"
                      item-text="name" item-value="_id"
                      @change="getGenreList()"
                      attach
                      chips
                      label="Categorias"                              
                      ></v-select>

                  </v-col>

                  <v-col  cols="12" class="col-md-6 col-xs-6 mt-6">
                    <v-select
                      v-model="restaurante.genre"
                     :items="generos"
                      item-text="name"
                      item-value="_id" 
                      attach
                      chips
                      label="Gênero"
                    ></v-select>                 
                  </v-col>
                </v-row>

              </div>       
            </v-form>
          </v-card-text>
          <v-row class="justify-center mx-auto text-center  mt-2">
            <v-btn
            depressed class="mb-5"
            color="primary ml-2"
            @click="postRestaurante()"
            > 
              Salvar
            </v-btn>
          </v-row>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios'
import { app, http } from '@/server'
import service from "@/config/services"
import { replaceUrl } from '@/functions/helpers'
import {showError, showSucess} from '@/global'

export default{
  data(){
    return{
      estados: null,
      categorias:null,
      municipios: null,
      error: false,
      categorias: null,
      previewImage: null,
      generos:null,
      photo1: null,
      modal1: false,
      modal2:false,
      time1: null,
      time2: null,
      loading: false,
      dias:[
        {name:"Segunda", _id: 0},
        {name:"Terça", _id: 1},
        {name:"Quarta", _id: 2},
        {name:"Quinta", _id: 3},
        {name:"Sexta", _id: 4},
        {name:"Sábado", _id: 5},
        {name:"Domingo", _id: 6},
      ],
      types:["MARKETING", "SELL"],
      restaurante:{
              name: "",
              type: "",
              openTime: {
                days: [],
                startTime: "",
                endTime: "",
              },
              photo: {
                location: null,
                key: null
              },
              category: "",
              genre: "",
              city: {
                state: "",
                name: "",
              },
              description: "",
              googleLink:"",
      }
    }
  },
  computed: {
   show: {
     get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
          }
      }
    }
  },
    created() {
    this.getEstados();
    this.getCategoryList();
    },

    props: {
    visible: Boolean,
    evento: Object
    },
    methods:{
      MakeTime(){
      this.restaurante.openTime.startTime = this.time1
      },
      MakeTime2(){
      this.restaurante.openTime.endTime = this.time2
      },
      selectImage () {
      this.$refs.fileInput.click()
      },
      pickFile1() {
        this.photo1 = this.$refs.fileInput.files[0];
        const formData = new FormData();
        formData.append('file', this.photo1);
        service.uploadImage.data = formData
        http.request(service.uploadImage).then((res) => { 
          this.restaurante.photo.key = res.data.key
          this.restaurante.photo.location =res.data.location
          console.log(this.evento)
          let input = this.$refs.fileInput
          let file = input.files
          if (file && file[0]) {
            let reader = new FileReader
            reader.onload = e => {
              this.previewImage = e.target.result
            }
            reader.readAsDataURL(file[0])
            this.$emit('input', file[0])
          }
        }).catch(this.loading = true, this.previewImage = null, this.error = false)
        .catch((err)=>{
          if (err != 200){    
            this.loading = false
            this.error = true
            showError(err)
          }
        })
      },
      getEstados() {
      axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((res) => {
          this.estados = res.data;
        })
       .catch((error) => {
          console.log(error);
        });
      },
      getCidades() {
      axios
        .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.restaurante.city.state}/municipios`)
        .then((res) => {
          this.municipios = res.data;
        })
        .catch()
      },
      getCategoryList(){
      http.request(service.getCategoryList)
        .then((res) => {
          this.categorias = res.data;
        })
      .catch()
      },
      getGenreList(){
      const url = service.getGenreList.url
      service.getGenreList.url = replaceUrl(url, '{category_id}', this.restaurante.category)

      http.request(service.getGenreList)
        .then((res) => {
          service.getGenreList.url = '/genre?categoryId={category_id}'

          this.generos = res.data;
        })
        .catch()
      },
      postRestaurante(){
        console.log(this.restaurante)
        app.createRestaurante.data = this.restaurante
        http.request(app.createRestaurante)
        .then(res =>{
            if(res.status === 200){
              showSucess(res)
            }
        })
        .catch((err)=>{
          if (err != 200){    
            showError(err)
          }
        })
        }
    }
    
}
</script>
<style scoped>
#myFileInput{
  position:absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.imagem3-upload{
  border-radius: 22px;
  width: 150px;
  height: 150px;
  margin-top: 10px;
  border: 2px solid #D356F3;
  color: #D356F3;
  line-height: 70px;
  cursor: pointer;
  
}
.imagePreviewWrapper {
   border-radius: 20px;
   line-height: 80px;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    margin-top: -70px;
}
.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

</style>