<template>
  <v-app>
    <Sidebar/>

    <v-flex class="alltheitens text-white " v-if="loadingRestaurantes == false">
      <v-row >
        <v-flex v-for="item in restaurantes" class="justify-center mx-auto" :key="item.id" xs8 sm5 md4 lg4>
                <v-card shaped class="cards-padrao ">
                  <div class="mb-5 card-img">
                    <img  v-if="item.photo.location != null" :src="`${item.photo.location}`"/>
                    <v-icon
                    color="#000"  x-large class="loade"
                    v-else
                    >mdi-cloud-upload</v-icon>
                  </div>
                  <v-divider></v-divider>
                  <div class="card-text mb-2 ">
                    <h4 class=" justify-center mx-auto">{{item.name}}</h4>
                  </div>
                  <v-divider></v-divider>
                  <v-card-actions class=" justify-center mx-auto">
                    <v-btn color="red"  @click="deleteBar(item)">Deletar</v-btn>
                  </v-card-actions>

                </v-card>
        </v-flex>
      </v-row>

       <v-row class="justify-center d-flex mx-auto">
        <v-card-actions class="criar-evento justify-end">
              <v-btn
              rounded x-large
              @click.stop="criarestaurante=true">
                <h3>Restaurante/Bar </h3><v-icon
                  icon:mdi-view-dashboard
                  color="purple"
                  large
                >mdi-plus</v-icon>
              </v-btn>
        </v-card-actions>            
      </v-row>
      <CriarRestaurante :visible="criarestaurante" @close="criarestaurante=false" @atualizar="GetRestaurantes()"/>

    </v-flex>
    <!-- Loading-->
     <v-flex class="justify-center mx-auto" xs8 sm8 md4 lg4>

      <v-row class=" justify-center mx-auto">
          <v-progress-circular v-if="loadingRestaurantes == true"
          :size="150" 
          :width="10" style="margin-top:150px" 
          color="purple" class="d-flex justify-center"
          indeterminate
          ></v-progress-circular>
      </v-row>
     </v-flex>
  </v-app>
</template>
<script>
import Sidebar from '@/components/Sidebar/Sidebar.vue'
import { showError} from '@/global'
import { app, http } from '@/server'
import { replaceUrl } from '@/functions/helpers'
import CriarRestaurante from '@/components/Restaurante/Criarestaurante.vue'
export default {
  name: 'Restaurante',
  components: {
    Sidebar,
    CriarRestaurante
  },
  data(){
    return{
      criarestaurante: false,
      loadingRestaurantes: false,
      modal: false,
      modal12: false,
      restaurantes: null,
    }
  },
    mounted() {
    document.title = 'Restaurantes'
    this.GetBares()
  },
  methods:{
    GetBares(){
      http.request(app.getRestaurante)
      .then(res => {
        this.loadingRestaurantes = false
        this.restaurantes = res.data
      })
      .catch(this.loadingRestaurantes = true)
      .catch((err)=>{
          if (err != 200){    
            this.loadingRestaurantes = false
            showError()
          }
        })
    },
    deleteBar(item){
            console.log(item)

      const url = app.deleteRestaurante.url
      app.deleteRestaurante.url = replaceUrl(url, '{id}', item._id)

            http.request(app.deleteRestaurante)
            .then(res => {
                if(res.status == 200){
                    app.deleteRestaurante.url = '/restaurant/{id}'
                    this.GetBares()
                    this.loadingRestaurantes = false
                    showSucess(res)
                }
            })
            .catch( this.loadingRestaurantes = true)
            .catch((err)=>{
                if (err != 200){    
                    showError(err)
                    this.loadingRestaurantes = false
                }
            })

    }
  }

}
</script>
<style scoped>
.cards-padrao{
  text-align: center;
  width: 250px !important;
  height: 360px !important;
  padding: 10px;
  margin:5px;
}
.card-img{
  height: 210px !important;
  margin: auto;
  width: 200px;
}
.card-img img{
    height: 100%;
    width: 100%;
    border-radius: 25px;
}
.card-text{
    max-width:200px !important;
    height:50px !important;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}
.loade{
 margin-top: 90px;
}
</style>